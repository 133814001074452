import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import resume from '../images/Bilge_Altay_Resume.pdf';

const email = 'bilgealtay@gmail.com';

const Contact = () => {
    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };
    return (
        <section id="contact" className="my-5">
            <Container className="text-center">
                <h2>Contact Us</h2>
                <p>Would you like to work with Raven Consultancy? Awesome!</p>
                <Row>
                    <Col>
                        <Button
                            onClick={handleEmailClick}
                            variant="link"
                            className="my-3"
                        >
                            Let's Talk
                        </Button>
                        <Button
                            href={resume}
                            target="_blank"
                            variant="link"
                            className="my-3"
                        >
                            Download Resume
                        </Button>
                    </Col>
                </Row>

            </Container>
        </section>
    );
};

export default Contact;