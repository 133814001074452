import React from 'react';
import NavbarComponent from './components/Navbar';
import Hero from './components/HeroSection';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
      <div className="App bg-black text-white">
        <NavbarComponent />
        <Hero />
        <About />
        <Services />
        <Contact />
        <Footer />
      </div>
  );
}

export default App;
