import React from 'react';
import { Container, Button } from 'react-bootstrap';

function HeroSection() {
    return (
        <section id="hero" className="my-5">
            <Container>
                <h1>Welcome to Raven Consultancy LTD.</h1>
                <p>Raven Consultancy is a consulting software company which provides solutions with latest and modern technologies.</p>
                <Button variant="light" href="#services">Learn More</Button>
            </Container>
        </section>
    );
}

export default HeroSection;
