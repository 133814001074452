import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import serviceImage from '../images/rear-view-programmer-working-all-night-long.jpg';

const Services = () => {
    return (
        <section id="services" className="my-5">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <h1>Our Services</h1>
                        <Row>
                            <Col>
                                <div>
                                    <h3>Software Development and Technical Consulting</h3>
                                    <div className="text-white-50">
                                        <p>
                                            Raven Consultancy is a consulting software company which provides solutions with latest and modern technologies.
                                        </p>
                                        <p className="mb-4">Depending on your requirements, we structure our offering as fixed price work packages or provide service on a time and material basis.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <h3>Software Development Training</h3>
                                    <div className="text-white-50">
                                        <p>
                                            Improve your skills to the next level with courses on the most popular programming languages, developer tools, software practices and application development platforms.
                                        </p>
                                        <p className="mb-4">We provide Software Training for Server Side Programming training with Java and Javascript, Web application programming training with React/Redux and Introduction to Application Deployment Tools.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <motion.div
                            initial={{opacity: 0, x: 100}}
                            animate={{opacity: 1, x: 0}}
                            transition={{duration: 0.5}}
                        >
                            <Image src={serviceImage} alt="Services Image" fluid/>
                        </motion.div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Services;
