import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import aboutImage from '../images/pexels-pixabay-35550.jpg';

const About = () => {
    return (
        <section id="about" className="my-5">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Image src={aboutImage} alt="About Image" fluid />
                        </motion.div>
                    </Col>
                    <Col md={6}>
                        <h2>About Us</h2>
                        <p>
                            Raven Consultancy serves software consulting services for both backend and frontend
                            enterprise applications and user experience design with the up-to-date development tools and
                            techniques.
                        </p>
                        <p>
                            From the outset, we believe it’s important to understand your individual requirements,
                            constraints, and priorities. Raven Consultancy offers affordable prices, rapid and high
                            quality solutions to customers. Raven Consultancy is the part of its customers and do best
                            for achievements of its clients with expertise in software development, user experience
                            design and project management.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
