import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Github, Linkedin, Twitter } from 'react-bootstrap-icons';

const Footer = () => {
    return (
        <footer className="bg-dark text-white py-3">
            <Container>
                <Row className="text-center">
                    <Col>
                        <a href="https://github.com/bilgealtay" target="_blank" rel="noopener noreferrer">
                            <Github size={30} className="mx-2"/>
                        </a>
                        <a href="https://www.linkedin.com/in/bilge-altay-59949568/" target="_blank"
                           rel="noopener noreferrer">
                            <Linkedin size={30} className="mx-2"/>
                        </a>
                        <a href="https://twitter.com/bilge_altay" target="_blank" rel="noopener noreferrer">
                            <Twitter size={30} className="mx-2"/>
                        </a>
                    </Col>
                </Row>
                <p className="py-3">
                    © {new Date().getFullYear()} - Raven Consultancy LTD. All Rights Reserved.
                </p>
            </Container>
        </footer>
    );
};

export default Footer;
